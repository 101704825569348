<template>
  <VueFinalModal
    content-class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-md-down"
    display-directive="show"
  >
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading-text-2 fw-normal modal-title">
          Video hub
        </p>
        <button
          class="btn-close"
          type="button"
          aria-label="Закрыть"
          title="Закрыть"
          @click="$emit('confirm')"
        />
      </div>
      <div class="modal-body">
        <VideoHubList
          :id="id"
          :active-item="activeItem"
          :question-id="questionId"
          @change-video="changeVideo"
        />
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";
import type { CompanyVideo } from "~/entities/CompanyVideo";

//-----PROPS-----\\
defineProps({
  id: { type: String, required: true },
  questionId: { type: String, default: undefined },
  activeItem: { type: String, default: undefined },
});
const emits = defineEmits(['confirm', 'changeVideo']);

//-----VARIABLES-----\\

//-----STATE-----\\

//-----COMPUTED-----\\

//-----ASYNC-----\\

//-----METHODS-----\\
function changeVideo(item: CompanyVideo) {
  emits('changeVideo', item);
  emits('confirm');
}
</script>