<template>
  <div>
    <div
      v-show="count === 0 && !isLoading"
      class="text-center"
    >
      <span class="heading-section-3">Список пуст</span>
    </div>
    <div
      v-show="count > 0"
      class="row g-main"
    >
      <div
        v-for="item in 12"
        v-show="isLoading"
        class="col-12 col-sm-6 col-lg-4"
      >
        <button
          class="w-100 btn btn-outline-primary placeholder-glow"
          disabled
          style="height: 59px;"
        >
          <span class="fw-bold placeholder col-10 col-lg-5">placeholder</span>
          <small class="d-block placeholder col-11 col-lg-6 mx-auto">placeholder</small>
        </button>
      </div>
      <div
        v-for="(item, index) in videos"
        v-show="!isLoading"
        class="col-12 col-sm-6 col-lg-4"
      >
        <VideoHubListItem
          class="w-100"
          :is-active="item.id === activeItem"
          :name="item.name"
          :created="new Date(item.created * 1000)"
          :is-delete="item.isDelete"
          @click="changeVideo(item)"
          @delete-video="deleteVideo(index)"
        />
      </div>
      <PaginationComponent
        :no-link="true"
        :page="page"
        :per-page="perPage"
        :items-count="count"
        @change-page="get"
      />
    </div>
  </div>
  <ModalDeleteVideo />
  <VueFinalModal
    v-model="modalShow"
    content-class="modal-dialog modal-dialog-centered modal-md"
    display-directive="show"
  >
    <div class="modal-content rounded-20">
      <div class="modal-header">
        <button
          class="btn-close"
          type="button"
          aria-label="Закрыть"
          title="Закрыть"
          @click="modalShow = false"
        />
      </div>
      <div class="modal-body px-20">
        <p>Видео нельзя удалить, так как оно участвует в следующих вакансиях:</p>
        <div class="d-flex flex-column gap-5">
          <NuxtLink
            v-for="(item) in vacanciesWithVideo"
            class="text-decoration-none text-primary"
            :to="config.public.protocol + '://' + config.public.domain + '/company/' + props.id + '/vacancies/' + item.id + '/edit'"
          >
            {{ item.profession + ' ' + item.city }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import type { CompanyVideo } from "~/entities/CompanyVideo";
import { VueFinalModal } from 'vue-final-modal';
import { useModal } from "vue-final-modal";
import ModalDeleteVideo from "~/components/modal/ModalDeleteVideo.vue";

const config = useRuntimeConfig();

//-----PROPS-----\\
const props = defineProps({
  id: { type: String, default: undefined },
  questionId: { type: String, default: undefined },
  activeItem: { type: String, default: undefined },
});
const emits = defineEmits(['changeVideo']);

//-----VARIABLES-----\\
const perPage = 12;

//-----STATE-----\\
const isLoading = ref<boolean>(false);
const modalShow = ref<boolean>(false);
const deleteVideoIndex = ref<number|null>(null);
const page = ref<number>(1);
const count = ref<number>(0);
const videos = ref<CompanyVideo[]>([]);
const vacanciesWithVideo = ref<any>();
const useModalDeleteVideo = useModal({
  component: ModalDeleteVideo,
  attrs: {
    onConfirm() {
      useModalDeleteVideo.close();
    },
    onDeleteSuccess() {
      videos.value[deleteVideoIndex.value].isDelete = true;
    },
    onDeleteError(response: any) {
      vacanciesWithVideo.value = response;
      modalShow.value = true;
    }
  }
});

//-----ASYNC-----\\
if (props.id !== undefined) {
  isLoading.value = true;
  await get();
}

//-----METHODS-----\\
async function get(newPage: number|null = null) {
  if (newPage !== null) {
    page.value = newPage;
  }

  isLoading.value = true;
  await mainFetch(`companies/${props.id}/videos`, {
    method: 'GET',
    query: {
      question: props.questionId,
      perPage,
      page: newPage,
    }
  }).then((response) => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code !== 200) {
      return;
    }
    videos.value = response.companyVideos.items;
    videos.value.map(val => {
      val['isDelete'] = false;
      return val;
    });
    count.value = response.companyVideos.count;
  }).finally(() => {
    isLoading.value = false;
  });
}
function changeVideo(item: CompanyVideo) {
  if (props.activeItem === item.id) {
    return;
  }

  emits('changeVideo', item);
}

function deleteVideo(index: number) {
  if (props.activeItem === videos.value[index].id) {
    return;
  }
  deleteVideoIndex.value = index;
  useModalDeleteVideo.open();
  useModalDeleteVideo.options.attrs.id = videos.value[index].id;
  useModalDeleteVideo.options.attrs.idCompany = props.id;
}

</script>