export const useVideoSettings = (isDesktop: boolean, isAudio: boolean = true) => {
    return {
        video: {
            frameRate: { ideal: 30, max: 30 },
            facingMode: 'user',
            width: { min: 1280, ideal: isDesktop ? 1920 : 1280, max: 1920 },
            height: { min: 720, ideal: isDesktop ? 1080 : 720, max: 1080 },
            aspectRatio: 16/9,
        },
        audio: isAudio,
    };
}