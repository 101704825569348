<template>
  <VueFinalModal
    content-class="modal-dialog modal-dialog-centered modal-lg"
    display-directive="show"
  >
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading-header-2 fw-normal modal-title">
          Правила записи {{ isVacancy ? 'видео-вакансии' : 'видео-резюме' }}
        </p>
        <button
          class="btn-close"
          type="button"
          aria-label="Закрыть"
          title="Закрыть"
          @click="emits('confirm')"
        />
      </div>
      <div
        class="modal-body"
        style="white-space: pre-line"
      >
        <p v-if="!isVacancy">
          Запись видео-резюме – это важный шаг, который помогает работодателю увидеть вас как профессионала и личность.
          Чтобы ваше видео-резюме произвело положительное впечатление, придерживайтесь следующих правил:
        </p>
        <p v-else>
          Запись видео-вакансии – это важный шаг, который помогает соискателям увидеть вашу компанию и должность, которую вы предлагаете.
          Чтобы ваше видео-вакансия произвело положительное впечатление, придерживайтесь следующих правил:
        </p>
        <ol>
          <li>
            <span class="fw-bold">Разместите телефон горизонтально:</span>
            Если используете телефон для записи, то вы сохраните формат представления видео на площадке.
          </li>
          <li>
            <span class="fw-bold">Тишина и отсутствие отвлекающих факторов:</span>
            Выберите тихое место, где вас не будут отвлекать посторонние звуки и действия.
          </li>
          <li>
            <span class="fw-bold">Хорошее освещение:</span>
            Обеспечьте достаточное освещение, предпочтительно естественное. Лицо должно быть хорошо освещено, чтобы не было теней.
          </li>
          <li>
            <span class="fw-bold">Нейтральный фон:</span>
            Убедитесь, что фон нейтральный и не отвлекает внимание от вас. Избегайте ярких и загруженных фонов.
          </li>
          <li>
            <span class="fw-bold">Стабильность камеры:</span>
            Убедитесь, что камера фиксирована и не трясется.
            Используйте штатив или поставьте устройство на устойчивую поверхность.
          </li>
          <li>
            <span class="fw-bold">Микрофон:</span>
            Используйте внешний микрофон, если это возможно, для обеспечения четкости звука.
            Встроенные микрофоны могут улавливать посторонние шумы.
          </li>
          <li>
            <span class="fw-bold">Тестовая запись:</span>
            Запишите первый короткий тестовый ответ, чтобы проверить качество звука и исключить посторонние шумы.
            Убедитесь, что ваш голос звучит ясно и разборчиво.
          </li>
          <li>
            <span class="fw-bold">Четкая речь:</span>
            Говорите четко и уверенно, избегайте лишних слов, запинок и длинных пауз.
          </li>
          <li>
            <span class="fw-bold">Естественность:</span>
            Старайтесь выглядеть и звучать естественно. Не заучивайте текст дословно, а лучше подготовьте основные тезисы.
          </li>
          <li>
            <span class="fw-bold">Позитивный и доброжелательный тон:</span>
            Поддерживайте позитивный настрой, улыбайтесь, будьте доброжелательны.
          </li>
          <li>
            <span class="fw-bold">Язык тела:</span>
            Держите осанку, не сутультесь. Используйте жесты, но избегайте излишней жестикуляции.
            Смотрите прямо в камеру, чтобы создать ощущение зрительного контакта.
          </li>
        </ol>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";

defineProps({
  isVacancy: { type: Boolean, default: false }
});
const emits = defineEmits(['confirm']);
</script>

<style scoped lang="scss">

</style>