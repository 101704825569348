type UploadData = {
    answerId: string,
    isVacancy: boolean,
    file: Blob,
    isWebm: boolean,
};

export const useApiRecordUpload = () => {
    const runtimeConfig = useRuntimeConfig();

    return {
        getHash: async (blob: Blob): Promise<string> => {
            const arrayBuffer = await blob.arrayBuffer();
            const msgUint8 = new Uint8Array(arrayBuffer)

            const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
            const hashArray = Array.from(new Uint8Array(hashBuffer))
            return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        },
        upload: async (token: string, data: UploadData) => {
            const form = new FormData();
            form.append('answerId', data.answerId);
            form.append('isVacancy', String(data.isVacancy));
            form.append('file', data.file);
            form.append('isWebm', String(data.isWebm));

            return await useFetch(`${runtimeConfig.public.protocol}://record.${runtimeConfig.public.domain}/api/record`, {
                method: 'POST',
                headers: { Authorization: 'Bearer ' + token },
                body: form,
            });
        },
    };
}
