<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="step-number-line mx-sm-10 d-none d-sm-block invisible" />
    <template v-for="(isValid, index) in steps">
      <div
        v-if="index !== 0"
        class="step-number-line bg-gray mx-5 mx-sm-10"
      />
      <button
        class="step-number btn rounded-circle d-flex justify-content-center align-items-center shadow-1 flex-shrink-0 p-0"
        type="button"
        :class="{
          'valid': isValid,
          'active': index === activeStep,
        }"
        @click="changeStep(index)"
      >
        <span>{{ index + 1 }}</span>
        <svg class="w-100 h-100 p-5">
          <use xlink:href="/public/icons/main.svg#check" />
        </svg>
      </button>
    </template>
    <div class="step-number-line mx-sm-10 d-none d-sm-block invisible" />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";

//-----PROPS-----\\
defineProps({
  steps: { type: Array as PropType<boolean[]>, default: () => [] },
  activeStep: { type: Number, default: 0 },
});
const emits = defineEmits(['changeStep']);

//-----VARIABLES-----\\

//-----STATE-----\\

//-----COMPUTED-----\\

//-----ASYNC-----\\

//-----METHODS-----\\
function changeStep(index: number) {
  emits('changeStep', index);
}
</script>

<style scoped lang="scss">
.step-number {
  height: 48px;
  width: 48px;
  background-color: white;
  font-size: 28px;
  color: var(--bs-primary);

  & > svg { display: none; }

  &.valid {
    background-color: var(--bs-apply);
    color: var(--bs-primary);

    & > span { display: none; }
    & > svg { display: block; }
  }

  &.active {
    background-color: var(--bs-primary);
    color: white;
  }
}
.step-number-line {
  height: 1px;
  flex: 2;
}
</style>