<template>
  <VueFinalModal
    content-class="modal-dialog modal-dialog-centered modal-md"
    display-directive="show"
  >
    <div class="modal-content rounded-20 p-10">
      <div class="modal-body p-20">
        <p class="mb-34">
          Вы действительно хотите удалить данное видео?
        </p>
        <div class="d-flex justify-content-end gap-20">
          <button
            class="btn btn-outline-black"
            @click="confirm"
          >
            Отмена
          </button>
          <button
            class="btn btn-book text-white"
            @click="deleteVideo"
          >
            Удалить
          </button>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';

const { $toast } = useNuxtApp();

//-----PROPS-----\\
const emits = defineEmits(['confirm', 'deleteSuccess', 'deleteError']);

const props = defineProps({
  id: { type: String, default: '' },
  idCompany: { type: String, default: '' }
});

//-----METHODS-----\\
async function deleteVideo() {
  await mainFetch(`/companies/${props.idCompany}/videos/${props.id}`, {
    method: 'DELETE',
  }).then(response => {
    response = response.data.value??response.error.value?.data;
    if (response.vacanciesWithVideo !== undefined) {
      emits('deleteError', response.vacanciesWithVideo);
    } else {
      emits('deleteSuccess');
      $toast.success('Видео удалено');
    }
  });
  confirm();
}

function confirm() {
  emits('confirm');
}

</script>

<style scoped lang="scss">
//@import 'vue-toastification/src/scss/index';
</style>