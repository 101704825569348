<template>
  <div class="btn-group">
    <button
      class="btn w-100"
      :disabled="isDelete"
      :class="isActive ? 'btn-primary ' : 'btn-outline-primary '"
    >
      <span class="fw-bold">{{ name }}</span>
      <small class="d-block">
        {{ isDelete ? 'Удалено' : 'Создана: ' + created.toLocaleString(['ru-RU', 'en-US'], { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" }) }}
      </small>
    </button>
    <button
      class="btn btn-outline-primary p-0"
      @click.stop="emits('deleteVideo')"
    >
      <svg
        width="40"
        height="40"
      >
        <use xlink:href="/public/icons/cabinetSprite.svg#delete-forever" />
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
//-----PROPS-----\\
defineProps({
  name: { type: String, required: true },
  created: { type: Date, required: true },
  isActive: { type: Boolean, default: false },
  isDelete: { type: Boolean, default: false }
});

const emits = defineEmits(['deleteVideo']);
//-----VARIABLES-----\\

//-----STATE-----\\

//-----COMPUTED-----\\

//-----METHODS-----\\

</script>
